<div class="bloque-mujeres-parlamentarias">
    <div class="container-lg">
        <div class="my-container">
            <div class="section-title-container pt-5 pb-1">
                <h3 class="section-title">{{ "El Salvador" | uppercase }}</h3>
            </div>

            <div class="card-deck">
                <!--inicio 1 /sv Anaya-Johana-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/sv/1Anaya-Johana.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">ANAYA DE MURILLO JOHANA MICHELLE</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Anaya-Johana">Ver informacion</button>
                        <div class="modal fade bd-Anaya-Johana" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">ANAYA DE MURILLO JOHANA
                                                    MICHELLE</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: El Salvador</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/sv/1Anaya-Johana.jpg"
                                                            alt="ANAYA DE MURILLO, JOHANA MICHELLE">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Bachiller General con cinco ciclos de Administración de Empresas en
                                                    la Universidad Tecnológica. 2018 – 2019 Ministerio de Gobernación y
                                                    Desarrollo Territorial, San Salvador, El Salvador.
                                                </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputada al Parlamento Centroamericano </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 1 /sv Anaya-Johana-->

                <!--inicio 2 /sv Arias-Ana-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/sv/2Arias-Ana.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">ARIAS HERNÁNDEZ ANA LISSETH</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Arias-Ana">Ver informacion</button>
                        <div class="modal fade bd-Arias-Ana" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">ARIAS HERNÁNDEZ ANA
                                                    LISSETH</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: El Salvador</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top" src="assets/img/HD/sv/2Arias-Ana.jpg"
                                                            alt="ARIAS HERNÁNDEZ, ANA LISSETH">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Ingeniera Industrial, Universidad Tecnológica de El Salvador; con
                                                    preespecialidad en Lean Manufacturing, por la Universidad
                                                    Tecnológica de El Salvador. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputada al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 2 /sv Arias-Ana-->

                <!--inicio 3 /sv Barahona-Yiseel-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/sv/3Barahona-Yiseel.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">BARAHONA DE CAMPOS YISEEL BEATRIZ</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Barahona-Yiseel">Ver informacion</button>
                        <div class="modal fade bd-Barahona-Yiseel" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">BARAHONA DE CAMPOS YISEEL
                                                    BEATRIZ</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: El Salvador</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/sv/3Barahona-Yiseel.jpg"
                                                            alt="BARAHONA DE CAMPOS, YISEEL BEATRIZ">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Licenciatura en Ciencias Jurídicas en la Universidad Centroamericana
                                                    José Simeón Cañas y Maestría en Criminología en la Universidad
                                                    Europea del Atlántico. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputada al Parlamento Centroamericano </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 3 /sv Barahona-Yiseel-->

                <!--inicio 4 /sv Calderon-Donald-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/sv/4Calderon-Donald.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">CALDERON LAM DONALD RICARDO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Calderon-Donald">Ver informacion</button>
                        <div class="modal fade bd-Calderon-Donald" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">CALDERON LAM DONALD
                                                    RICARDO</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: El Salvador</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/sv/4Calderon-Donald.jpg"
                                                            alt="CALDERON LAM, DONALD RICARDO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 4 /sv Calderon-Donald-->

                <!--inicio 5 /sv Guardado-José-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/sv/5Guardado-José.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">GUARDADO MENJIVAR JOSÉ NELSON</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Guardado-José">Ver informacion</button>
                        <div class="modal fade bd-Guardado-José" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">GUARDADO MENJIVAR JOSÉ
                                                    NELSON</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: El Salvador</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/sv/5Guardado-José.jpg"
                                                            alt="GUARDADO MENJIVAR, JOSÉ NELSON">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Graduado como Médico y Cirujano Dental en la Universidad Nacional de
                                                    El Salvador. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>2016 – 2021 Diputado al Parlamento Centroamericano; 2018 Presidente
                                                    de la Comisión de Salud del Parlamento Centroamericano,
                                                    Vicepresidente de Organización y Área Legal del Partido GANA,
                                                    Director Nacional de Finanzas, Director Departamental de
                                                    Chalatenango, Jefe de Asesores Legislativos, Coordinador de Grupo
                                                    Parlamentario Y Diputado por el Departamento de Chalatenango. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 5 /sv Guardado-José-->

            </div>
            <div class="card-deck">

                <!--inicio 6 /sv Guatemala-Gilberto-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/sv/6Guatemala-Gilberto.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">GUATEMALA VILLALTA GILBERTO EFRAÍN</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Guatemala-Gilberto">Ver informacion</button>
                        <div class="modal fade bd-Guatemala-Gilberto" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">GUATEMALA VILLALTA
                                                    GILBERTO EFRAÍN</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: El Salvador</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/sv/6Guatemala-Gilberto.jpg"
                                                            alt="GUATEMALA VILLALTA, GILBERTO EFRAÍN">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Licenciatura en Ciencias Jurídicas en la Universidad Tecnológica de
                                                    El Salvador y Programador Analista en el Centro Internacional de
                                                    Computación. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>2021 Diputado al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 6 /sv Guatemala-Gilberto-->

                <!--inicio 7 /sv Hernández-Carlos-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/sv/7Hernández-Carlos.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">HERNÁNDEZ CASTILLO CARLOS RENÉ</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Hernández-Carlos">Ver informacion</button>
                        <div class="modal fade bd-Hernández-Carlos" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">HERNÁNDEZ CASTILLO CARLOS
                                                    RENÉ</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: El Salvador</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/sv/7Hernández-Carlos.jpg"
                                                            alt="HERNÁNDEZ CASTILLO, CARLOS RENÉ">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Licenciatura en Ciencias Jurídicas y Especialización en Derecho Penal
                                                    en la Universidad Modular Abierta, San Salvador. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Subdirector Ejecutivo de la Alcaldía Municipal de San Salvador,
                                                    Secretaría de la Mujer y Familia de la ciudad de San Salvador.
                                                    Diputado al Parlamento Centroamericano.
                                                    Subdirector Ejecutivo de la Alcaldía Municipal de San Salvador,
                                                    Secretaría de la Mujer y Familia de la ciudad de San Salvador.
                                                    Diputado al Parlamento Centroamericano.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 7 /sv Hernández-Carlos-->

                <!--inicio 8 /sv Hernández-Ana-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/sv/8Hernández-Ana.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">HERNÁNDEZ ESTRADA ANA GLENDA</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Hernández-Ana">Ver informacion</button>
                        <div class="modal fade bd-Hernández-Ana" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">HERNÁNDEZ ESTRADA ANA
                                                    GLENDA</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: El Salvador</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/sv/8Hernández-Ana.jpg"
                                                            alt="HERNÁNDEZ ESTRADA, ANA GLENDA">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 8 /sv Hernández-Ana-->

                <!--inicio 9 /sv Maravilla-Ángel-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/sv/9Maravilla-Ángel.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">MARAVILLA MURCIA ÁNGEL JHOANNE</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Maravilla-Ángel">Ver informacion</button>
                        <div class="modal fade bd-Maravilla-Ángel" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">MARAVILLA MURCIA ÁNGEL
                                                    JHOANNE</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: El Salvador</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/sv/9Maravilla-Ángel.jpg"
                                                            alt="MARAVILLA MURCIA, ÁNGEL JHOANNE">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Técnico en Ingeniería Eléctrica Ingeniero Eléctrico en el Instituto
                                                    Técnico Centroamericano. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputado al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 9 /sv Maravilla-Ángel-->

                <!--inicio 10 /sv Mejía-Ana-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/sv/10Mejía-Ana.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">MEJÍA RAYMUNDO ANA GUADALUPE</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Mejía-Ana">Ver informacion</button>
                        <div class="modal fade bd-Mejía-Ana" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">MEJÍA RAYMUNDO ANA
                                                    GUADALUPE</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: El Salvador</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top" src="assets/img/HD/sv/10Mejía-Ana.jpg"
                                                            alt="MEJÍA RAYMUNDO, ANA GUADALUPE">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 10 /sv Mejía-Ana-->

            </div>
            <div class="card-deck">

                <!--inicio 11 /sv Menjívar-José-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/sv/11Menjívar-José.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">MENJÍVAR BARAHONA JOSÉ FRANCISCO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Menjívar-José">Ver informacion</button>
                        <div class="modal fade bd-Menjívar-José" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">MENJÍVAR BARAHONA JOSÉ
                                                    FRANCISCO</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: El Salvador</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/sv/11Menjívar-José.jpg"
                                                            alt="MENJÍVAR BARAHONA, JOSÉ FRANCISCO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Licenciado en Ciencias de la Comunicación de la Universidad Don Bosco
                                                    de El Salvador; Asesor del Ministerio de Relaciones Exteriores de El
                                                    Salvador; Especialista en el Ministerio de Justicia y Seguridad
                                                    Publica de El Salvador y Director en el Consejo Dir </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputado al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 11 /sv Menjívar-José-->

                <!--inicio 12 /sv Mira-Heidy-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/sv/12Mira-Heidy.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">MIRA SARAVIA HEIDY CAROLINA</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Mira-Heidy">Ver informacion</button>
                        <div class="modal fade bd-Mira-Heidy" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">MIRA SARAVIA HEIDY
                                                    CAROLINA</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: El Salvador</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/sv/12Mira-Heidy.jpg"
                                                            alt="MIRA SARAVIA, HEIDY CAROLINA">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 12 /sv Mira-Heidy-->

                <!--inicio 13 /sv Núñez-Belinda-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/sv/13Núñez-Belinda.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">NÚÑEZ DE MONTES BELINDA BEATRIZ</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Núñez-Belinda">Ver informacion</button>
                        <div class="modal fade bd-Núñez-Belinda" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">NÚÑEZ DE MONTES BELINDA
                                                    BEATRIZ</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: El Salvador</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/sv/13Núñez-Belinda.jpg"
                                                            alt="NÚÑEZ DE MONTES, BELINDA BEATRIZ">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 13 /sv Núñez-Belinda-->

                <!--inicio 14 /sv Orellana-Bryan-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/sv/14Orellana-Bryan.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">ORELLANA HERNÁNDEZ BRYAN RENÉ</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Orellana-Bryan">Ver informacion</button>
                        <div class="modal fade bd-Orellana-Bryan" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">ORELLANA HERNÁNDEZ BRYAN
                                                    RENÉ</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: El Salvador</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/sv/14Orellana-Bryan.jpg"
                                                            alt="ORELLANA HERNÁNDEZ, BRYAN RENÉ">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Doctor en Medicina y Posgrado Gerencia de Salud en la Universidad
                                                    Evangélica; Curso Virtual de Gestión de Recursos Humanos en Salud,
                                                    Perú; Diplomado en Emergencias Pre – Hospitalarias en el Colegio
                                                    Aphem, México; Curso de Salud Ocupacional Frente al Cov </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputado al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 14 /sv Orellana-Bryan-->

                <!--inicio 15 /sv Quijano-Norman-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/sv/no-imagen.PNG" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">QUIJANO GONZÁLEZ NORMAN NOÉL</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Quijano-Norman">Ver informacion</button>
                        <div class="modal fade bd-Quijano-Norman" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">QUIJANO GONZÁLEZ NORMAN
                                                    NOÉL</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: El Salvador</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top" src="assets/img/HD/sv/no-imagen.PNG"
                                                            alt="QUIJANO GONZÁLEZ, NORMAN NOÉL">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 15 /sv Quijano-Norman-->

            </div>
            <div class="card-deck">

                <!--inicio 16 /sv Reyes-Ana-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/sv/16Reyes-Ana.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">REYES ARRIAZA ANA ELEONORA</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Reyes-Ana">Ver informacion</button>
                        <div class="modal fade bd-Reyes-Ana" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">REYES ARRIAZA ANA
                                                    ELEONORA</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: El Salvador</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top" src="assets/img/HD/sv/16Reyes-Ana.jpg"
                                                            alt="REYES ARRIAZA, ANA ELEONORA">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Licenciada en Administración de Empresas con especialidad en
                                                    Computación en la Universidad Tecnológica de El Salvador, Consultora
                                                    en Gestión Empresarial y Asistencia Técnica en las MIPYMEs. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputada al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 16 /sv Reyes-Ana-->

                <!--inicio 17 /sv Reyes-David-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/sv/17Reyes-David.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">REYES MOLINA DAVID ERNESTO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Reyes-David">Ver informacion</button>
                        <div class="modal fade bd-Reyes-David" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">REYES MOLINA DAVID
                                                    ERNESTO</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: El Salvador</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/sv/17Reyes-David.jpg"
                                                            alt="REYES MOLINA, DAVID ERNESTO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Licenciatura en Mercadeo en la Universidad Dr. José Matías Delgado El
                                                    Salvador; Estudios de Gestión de Proyectos de Emprendimiento e
                                                    Innovación desde la Cooperación Internacional en la Universidad
                                                    Pontificia Católica de Valparaíso, Chile; Gerencia de Ad </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputado al Parlamento Centroamericano;Presidente y Secretario de la
                                                    Comisión de Trabajo y Previsión Social del PARLACEN;miembro de las
                                                    Comisiones Legislativas Familia, Niñez, Adolescencia, Adulto Mayor y
                                                    Personas con Discapacidad; Trabajo y Previsión Social; Juventud y
                                                    Deportes; Medio Ambiente y Cambio Climático; Obras Públicas;
                                                    Transporte y Vivienda de la Asamblea Legislativa de El Salvador.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 17 /sv Reyes-David-->

                <!--inicio 18 /sv Rivera-Cecilia-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/sv/18Rivera-Cecilia.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">RIVERA MÉNDEZ CECILIA GUADALUPE</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Rivera-Cecilia">Ver informacion</button>
                        <div class="modal fade bd-Rivera-Cecilia" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">RIVERA MÉNDEZ CECILIA
                                                    GUADALUPE</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: El Salvador</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/sv/18Rivera-Cecilia.jpg"
                                                            alt="RIVERA MÉNDEZ, CECILIA GUADALUPE">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Licenciatura en Ciencias Jurídicas en la Universidad Tecnológica de
                                                    El Salvador; Especialización en Derecho Procesal Civil y Mercantil;
                                                    Notaria de El Salvador acreditada por la Corte Suprema de Justicia;
                                                    Técnico en Servicios Turísticos Comerciales en el </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputada al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 18 /sv Rivera-Cecilia-->

                <!--inicio 19 /sv Sosa-Karina-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/sv/19Sosa-Karina.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">SOSA DE RODAS KARINA IVETTE</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Sosa-Karina">Ver informacion</button>
                        <div class="modal fade bd-Sosa-Karina" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">SOSA DE RODAS KARINA
                                                    IVETTE</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: El Salvador</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/sv/19Sosa-Karina.jpg"
                                                            alt="SOSA  DE RODAS, KARINA IVETTE">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 19 /sv Sosa-Karina-->

                <!--inicio 20 /sv Vargas-Cecilia-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/sv/20Vargas-Cecilia.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">VARGAS MARTÍNEZ CECILIA EVELYN</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Vargas-Cecilia">Ver informacion</button>
                        <div class="modal fade bd-Vargas-Cecilia" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">VARGAS MARTÍNEZ CECILIA
                                                    EVELYN</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: El Salvador</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/sv/20Vargas-Cecilia.jpg"
                                                            alt="VARGAS MARTÍNEZ, CECILIA EVELYN">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 20 /sv Vargas-Cecilia-->

            </div>



            <div class="section-title-container pt-5 pb-1">
                <h3 class="section-title">{{ "Guatemala" | uppercase }}</h3>
            </div>
            <div class="card-deck">

                <!--inicio 1 /gt Alvarado-Manuela-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/1Alvarado-Manuela.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">ALVARADO LÓPEZ MANUELA</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Alvarado-Manuela">Ver informacion</button>
                        <div class="modal fade bd-Alvarado-Manuela" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">ALVARADO LÓPEZ MANUELA
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: Terapeuta Social, Enfermera
                                                                Profesional y Maestra de Educación Primaria.</li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/gt/1Alvarado-Manuela.jpg"
                                                            alt="ALVARADO LÓPEZ, MANUELA">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Terapeuta Social, Enfermera Profesional y Maestra de Educación
                                                    Primaria. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Interlocutora para la incidencia en el avance del cumplimiento de
                                                    Derechos Individuales y Colectivos de los Pueblos Indígenas en la
                                                    institucionalidad del Estado de Guatemala; Diputada por el
                                                    Departamento de Quetzaltenango en el Congreso de la República,
                                                    1996-1999; Subdirectora de la Comisión Presidencial y Coordinadora
                                                    de Políticas Públicas del Ejecutivo en Derechos Humanos COPREDEH;
                                                    Integrante del equipo fundador de organizaciones políticas de
                                                    Pueblos Indígenas; Representante de las organizaciones de mujeres en
                                                    el Consejo Nacional para el Cumplimiento de los Acuerdos de Paz,
                                                    CNAT; Presidenta de la Comisión de la Mujer, el Menor y la Familia,
                                                    presidió el anteproyecto del Instituto Nacional de la Mujer INAM.
                                                    Diputada al Parlamento Centroamericano para el periodo 2024-2028.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 1 /gt Alvarado-Manuela-->

                <!--inicio 2 /gt Barreda-Carlos-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/2Barreda-Carlos.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">BARREDA TARACENA CARLOS ALBERTO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Barreda-Carlos">Ver informacion</button>
                        <div class="modal fade bd-Barreda-Carlos" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">BARREDA TARACENA CARLOS
                                                    ALBERTO</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/gt/2Barreda-Carlos.jpg"
                                                            alt="BARREDA TARACENA, CARLOS ALBERTO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Licenciatura en Economía, Universidad de San Carlos de Guatemala.
                                                </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Viceministro de Finanzas Públicas 2008-2009; Asesor Económico de la
                                                    Presidencia 2010; Diputado al Congreso de la República de Guatemala
                                                    periodos 2012-2016, 2016-2020, 2020-2024; Diputado al Parlamento
                                                    Centroamericano periodo 2024-2028. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 2 /gt Barreda-Carlos-->

                <!--inicio 3 /gt Bernat-Ana-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/3Bernat-Ana.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">BERNAT COFIÑO ANA INGRID</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Bernat-Ana">Ver informacion</button>
                        <div class="modal fade bd-Bernat-Ana" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">BERNAT COFIÑO ANA INGRID
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top" src="assets/img/HD/gt/3Bernat-Ana.jpg"
                                                            alt="BERNAT COFIÑO, ANA INGRID">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Licenciada en Mercadeo, Universidad Rafael Landivar; Maestría en
                                                    Consejería Psicológica y Salud Mental, Universidad del Valle de
                                                    Guatemala.
                                                    Profesorado de Educación Media y Ciencias Sociales, Universidad
                                                    Galileo;
                                                </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Secretaria General del Partido Político VALOR, Presidir el Comité
                                                    Ejecutivo Nacional, Ejercer la Representación Legal, Ejecutar y
                                                    hacer que se ejecuten las Resoluciones de Asamblea Nacional o del
                                                    CEN y todas las atribuciones y obligaciones según el Artículo 33 de
                                                    la Ley Electoral y de Partidos Políticos, todo esto relacionado al
                                                    Partido Valor. Diputada al Parlamento Centroamericano períodos
                                                    2020-2024 y 2024-2028. Vicepresidenta 2023. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 3 /gt Bernat-Ana-->

                <!--inicio 4 /gt Castellanos-María-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/4Castellanos-María.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">CASTELLANOS PINELO MARÍA EUGENIA</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Castellanos-María">Ver informacion</button>
                        <div class="modal fade bd-Castellanos-María" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">CASTELLANOS PINELO MARÍA
                                                    EUGENIA</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/gt/4Castellanos-María.jpg"
                                                            alt="CASTELLANOS PINELO, MARÍA EUGENIA">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Licenciatura como Cirujano Dentista, Universidad de San Carlos de
                                                    Guatemala; Maestría en Ciencias Criminalísticas, Universidad Mariano
                                                    Gálvez de Guatemala. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputada al Congreso de la República de Guatemala de la IX
                                                    Legislatura, periodo 2020-2023 por el Partido VAMOS, Presidente de
                                                    la Comisión de Educación, Ciencia y Tecnología periodo 2022-2023,
                                                    Miembro de la Comisión de Relaciones Exteriores 2023; Miembro de la
                                                    Comisión de Asuntos Electorales 2022; Miembro de la Comisión de
                                                    Salud y Asistencia Social 2022; Miembro de la Comisión de
                                                    Gobernación 2022; Miembro de la Comisión de Turismo 2021; Miembro de
                                                    la Comisión de Economía y Comercio Exterior 2021; Miembro de la
                                                    Comisión de Relaciones Exteriores 2021; Miembro de la Comisión de
                                                    Seguridad Alimentaria 2021; Miembro de la Comisión de Salud y
                                                    Asistencia Social 2020; Miembro de la Comisión de Economía y
                                                    Comercio Exterior 2020; Miembro de la Comisión de Relaciones
                                                    Exteriores 2020; Miembro de la Comisión de la Mujer 2020; Miembro
                                                    del Foro de Diputadas 2020, Secretaria del Foro de Diputadas 2021;
                                                    Diputada al Parlamento Centroamericano período 2024-2028. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 4 /gt Castellanos-María-->

                <!--inicio 5 /gt Castillo-Roberto-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/5Castillo-Roberto.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">CASTILLO BARRIENTOS ROBERTO RODRIGO ARMANDO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Castillo-Roberto">Ver informacion</button>
                        <div class="modal fade bd-Castillo-Roberto" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">CASTILLO BARRIENTOS
                                                    ROBERTO RODRIGO ARMANDO</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/gt/5Castillo-Roberto.jpg"
                                                            alt="CASTILLO BARRIENTOS, ROBERTO RODRIGO ARMANDO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Bachillerato en Administración Industrial y Perito en Dibujo de
                                                    Construcción y por Computadora, Tecnológico Federico Taylor. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputado al Parlamento Centroamericano período 2024-2028. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 5 /gt Castillo-Roberto-->

            </div>
            <div class="card-deck">

                <!--inicio 6 /gt Castillo-Guillermo-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/6Castillo-Guillermo.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">CASTILLO REYES GUILLERMO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Castillo-Guillermo">Ver informacion</button>
                        <div class="modal fade bd-Castillo-Guillermo" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">CASTILLO REYES GUILLERMO
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/gt/6Castillo-Guillermo.jpg"
                                                            alt="CASTILLO REYES, GUILLERMO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 6 /gt Castillo-Guillermo-->

                <!--inicio 7 /gt DeLeon-Edgar-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/7DeLeon-Edgar.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">DE LEÓN TORRES EDGAR ANTONIO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-DeLeon-Edgar">Ver informacion</button>
                        <div class="modal fade bd-DeLeon-Edgar" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">DE LEÓN TORRES EDGAR
                                                    ANTONIO</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/gt/7DeLeon-Edgar.jpg"
                                                            alt="DE LEÓN TORRES, EDGAR ANTONIO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 7 /gt DeLeon-Edgar-->

                <!--inicio 8 /gt DeLeon-Sandra-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/8DeLeon-Sandra.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">DE LEÓN TORRES SANDRA RAQUEL</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-DeLeon-Sandra">Ver informacion</button>
                        <div class="modal fade bd-DeLeon-Sandra" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">DE LEÓN TORRES SANDRA
                                                    RAQUEL</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/gt/8DeLeon-Sandra.jpg"
                                                            alt="DE LEÓN TORRES, SANDRA RAQUEL">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 8 /gt DeLeon-Sandra-->

                <!--inicio 9 /gt Giammattei-Alejandro-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/9Giammattei-Alejandro.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">GIAMMATTEI FALLA ALEJANDRO EDUARDO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Giammattei-Alejandro">Ver informacion</button>
                        <div class="modal fade bd-Giammattei-Alejandro" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">GIAMMATTEI FALLA
                                                    ALEJANDRO EDUARDO</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/gt/9Giammattei-Alejandro.jpg"
                                                            alt="GIAMMATTEI FALLA, ALEJANDRO EDUARDO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 9 /gt Giammattei-Alejandro-->

                <!--inicio 10 /gt Girón-Ruddy-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/10Girón-Ruddy.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">GIRÓN LÓPEZ RUDDY MIGUEL</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Girón-Ruddy">Ver informacion</button>
                        <div class="modal fade bd-Girón-Ruddy" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">GIRÓN LÓPEZ RUDDY MIGUEL
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/gt/10Girón-Ruddy.jpg"
                                                            alt="GIRÓN LÓPEZ, RUDDY MIGUEL">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 10 /gt Girón-Ruddy-->

            </div>
            <div class="card-deck">

                <!--inicio 11 /gt Godoy-Pedro-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/11Godoy-Pedro.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">GODOY MÉNDEZ PEDRO PASCUAL</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Godoy-Pedro">Ver informacion</button>
                        <div class="modal fade bd-Godoy-Pedro" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">GODOY MÉNDEZ PEDRO
                                                    PASCUAL</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/gt/11Godoy-Pedro.jpg"
                                                            alt="GODOY MÉNDEZ, PEDRO PASCUAL">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 11 /gt Godoy-Pedro-->

                <!--inicio 12 /gt Gutiérrez-Karla-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/12Gutiérrez-Karla.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">GUTIÉRREZ HERRERA KARLA LISBETH</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Gutiérrez-Karla">Ver informacion</button>
                        <div class="modal fade bd-Gutiérrez-Karla" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">GUTIÉRREZ HERRERA KARLA
                                                    LISBETH</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/gt/12Gutiérrez-Karla.jpg"
                                                            alt="GUTIÉRREZ HERRERA, KARLA LISBETH">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Abogada en ejercicio de la profesión liberal desde 2013; Miembro de
                                                    Comisiones de Postulación para elegir Cortes de Apelaciones de
                                                    Guatemala 2014-2019; Revisora de la Unidad de Tesis de la
                                                    Universidad de San Carlos de Guatemala 2013-2014; Abogada Asesora
                                                </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Actual Vice Presidenta del PARLACEN por el Estado de Guatemala para
                                                    el período 2023-2024. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 12 /gt Gutiérrez-Karla-->

                <!--inicio 13 /gt Herrera-José-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/13Herrera-José.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">HERRERA FUNES JOSÉ SALOMÓN</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Herrera-José">Ver informacion</button>
                        <div class="modal fade bd-Herrera-José" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">HERRERA FUNES JOSÉ
                                                    SALOMÓN</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/gt/13Herrera-José.jpg"
                                                            alt="HERRERA FUNES, JOSÉ SALOMÓN">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 13 /gt Herrera-José-->

                <!--inicio 14 /gt Mac-Mónica-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/14Mac-Mónica.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">MAC DONALD GALLARDO MÓNICA SCARLETT</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Mac-Mónica">Ver informacion</button>
                        <div class="modal fade bd-Mac-Mónica" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">MAC DONALD GALLARDO
                                                    MÓNICA SCARLETT</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/gt/14Mac-Mónica.jpg"
                                                            alt="MAC DONALD GALLARDO, MÓNICA SCARLETT">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Licenciada en Ciencias Jurídicas y Sociales, Títulos de Abogada y
                                                    Notaria, Universidad Rafael Landivar; Especialización en Derecho
                                                    Electoral, Universidad de San Carlos de Guatemala/Tribunal Supremo
                                                    Electoral; Máster en Derecho Procesal Constitucional, Un </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Secretaria General del Ministerio de Energía y Minas periodo
                                                    2018-2020; Directora de la Unidad de Cooperación Internacional en el
                                                    Ministerio de Energía y Minas periodo 2020-2024; Magistrada Suplente
                                                    de la Sala de la Corte de Apelaciones, Corte Suprema de Justicia de
                                                    la República de Guatemala periodo 2014-2023; Diputada al Parlamento
                                                    Centroamericano periodo 2024-2028. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 14 /gt Mac-Mónica-->

                <!--inicio 15 /gt Martínez-Karla-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/15Martínez-Karla.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">MARTÍNEZ HERNÁNDEZ KARLA ANDREA</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Martínez-Karla">Ver informacion</button>
                        <div class="modal fade bd-Martínez-Karla" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">MARTÍNEZ HERNÁNDEZ KARLA
                                                    ANDREA</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/gt/15Martínez-Karla.jpg"
                                                            alt="MARTÍNEZ HERNÁNDEZ, KARLA ANDREA">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Medico y Cirujano, Universidad de San Carlos de Guatemala;
                                                    Especialización Científica, Universidad de San Carlos de Guatemala;
                                                    Especialización en Medicina Estética y Anti-Envejecimiento,
                                                    Universidad Autónoma de México y Universidad Mesoamericana de Guate
                                                </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputada Distrital periodo 2016-2020; Diputada por el Listado
                                                    Nacional periodo 2020-2024; Presidente de la Comisión de Salud y
                                                    Asistencia Social para los periodos Legislativos 2017 y 2019;
                                                    Segunda Secretaria de la Junta Directiva del Congreso de la
                                                    República para el Periodo Legislativo 2018; Vicepresidenta de la
                                                    Comisión de Salud y Asistencia Social, Subjefa de la Bancada UCN y
                                                    Miembro de diversas Comisiones de Trabajo en el Congreso de la
                                                    República de Guatemala para el periodo 2020; Integrante de diversas
                                                    Comisiones de Trabajo en el Congreso de la República de Guatemala
                                                    para los años 2021y 2020; Vicepresidenta del Foro de Diputadas del
                                                    Congreso de la República de Guatemala para los años 2021 y 2022;
                                                    Secretaria de la Comisión de la Mujer e integrante de diversas
                                                    Comisiones de Trabajo del Congreso de la República de Guatemala para
                                                    el periodo 2023; Diputada al Parlamento Centroamericano periodo 2024
                                                    -2028. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 15 /gt Martínez-Karla-->

            </div>
            <div class="card-deck">

                <!--inicio 16 /gt Medina-Nery-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/16Medina-Nery.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">MEDINA RICCO NERY OSWALDO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Medina-Nery">Ver informacion</button>
                        <div class="modal fade bd-Medina-Nery" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">MEDINA RICCO NERY OSWALDO
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/gt/16Medina-Nery.jpg"
                                                            alt="MEDINA RICCO, NERY OSWALDO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Abogado y Notario, Licenciado en Ciencias Jurídicas y Sociales,
                                                    Universidad Da Vinci de Guatemala; Curso de Altos Estudios
                                                    Estratégicos en el Comando Superior de Estudios del Ejercito de
                                                    Guatemala COSEDE, Ministerio de la Defensa Nacional. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Asesor Profesional Externo, Ministerio de Gobernación; Asesor del
                                                    Despacho del Segundo Vice Ministerio, Ministerio de Gobernación;
                                                    Diputado al Parlamento Centroamericano período 2024-2028. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 16 /gt Medina-Nery-->

                <!--inicio 17 /gt Ordoñez-Lilian-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/17Ordoñez-Lilian.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">ORDOÑEZ MORALES DE LARA LILIAN JUDITH</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Ordoñez-Lilian">Ver informacion</button>
                        <div class="modal fade bd-Ordoñez-Lilian" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">ORDOÑEZ MORALES DE LARA
                                                    LILIAN JUDITH</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/gt/17Ordoñez-Lilian.jpg"
                                                            alt="ORDOÑEZ MORALES DE LARA, LILIAN JUDITH">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Secretaria Comercial con Orientación en Computación; Estudiante de la
                                                    Licenciatura en Ciencias Jurídicas y Sociales, Universidad Rural de
                                                    Guatemala; Diplomado en Estudios Parlamentarios Estratégicos,
                                                    Instituto para la Competitividad Económica; Diplomado </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Secretaria de Diputado del Congreso de la República de Guatemala en
                                                    los años 2020-2023; Diputada al Parlamento Centroamericano periodo
                                                    2024-2028. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 17 /gt Ordoñez-Lilian-->

                <!--inicio 18 /gt Romero-Raúl-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/18Romero-Raúl.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">ROMERO SEGURA RAÚL</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Romero-Raúl">Ver informacion</button>
                        <div class="modal fade bd-Romero-Raúl" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">ROMERO SEGURA RAÚL</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/gt/18Romero-Raúl.jpg"
                                                            alt="ROMERO SEGURA, RAÚL">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Maestría en Desarrollo y Descentralización Laboral en la Universidad
                                                    de Quito, Ecuador; Licenciatura en Ciencias Políticas, Universidad
                                                    de San Carlos de Guatemala. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Actual Secretario del PARLACEN por el Estado de Guatemala, período
                                                    2023-2024; Ministro de Desarrollo Social 2020-2022;Diputado al
                                                    Congreso de la República de Guatemala 2015-2019; Representante de la
                                                    Secretaría Ejecutiva de la Presidencia, miembro del Consejo Nacional
                                                    de Alfabetización (CONALFA); Presidente o integrante en comisiones
                                                    de trabajo sobre cambio climático, seguridad nacional, juventud,
                                                    apoyo técnico y derechos humanos.
                                                    También trabajó en la Secretaría de la Paz (SEPAZ), participó en el
                                                    programa de Descentralización y Desarrollo Local de Cooperación
                                                    Técnica Alemana GTZ.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 18 /gt Romero-Raúl-->

                <!--inicio 19 /gt Sis-Edgar-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/19Sis-Edgar.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">SIS BACHAN EDGAR ARTURO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Sis-Edgar">Ver informacion</button>
                        <div class="modal fade bd-Sis-Edgar" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">SIS BACHAN EDGAR ARTURO
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top" src="assets/img/HD/gt/19Sis-Edgar.jpg"
                                                            alt="SIS BACHAN, EDGAR ARTURO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 19 /gt Sis-Edgar-->

                <!--inicio 20 /gt Vargas-Jorge-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/20Vargas-Jorge.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">VARGAS MORALES JORGE ESTUARDO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Vargas-Jorge">Ver informacion</button>
                        <div class="modal fade bd-Vargas-Jorge" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">VARGAS MORALES JORGE
                                                    ESTUARDO</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/gt/20Vargas-Jorge.jpg"
                                                            alt="VARGAS MORALES, JORGE ESTUARDO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Cirujano Dentista, Facultad de Odontología, Universidad Mariano
                                                    Gálvez de Guatemala. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Concejal IV de la Municipalidad de Puerto Barrios, Departamento de
                                                    Izabal en el Periodo 2004-2008; en el Organismo Legislativo,
                                                    Congreso de la República de Guatemala Unidad Nacional de la
                                                    Esperanza UNE: Primera Casilla como Diputado al Congreso de la
                                                    República de Guatemala en Listado Nacional para el periodo
                                                    2016-2019, Primera Casilla como Diputado reelecto al Congreso de la
                                                    República de Guatemala en Listado Nacional para el periodo
                                                    2020-2024, Jefe de Bancada Unidad Nacional de la Esperanza UNE en el
                                                    periodo 2021-2023; Diputado al Parlamento Centroamericano periodo
                                                    2024 -2028. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 20 /gt Vargas-Jorge-->

            </div>
            <div class="card-deck">

                <!--inicio 21 /gt Villatoro-Stuart-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/21Villatoro-Stuart.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">VILLATORO PERDOMO STUART ROMEO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Villatoro-Stuart">Ver informacion</button>
                        <div class="modal fade bd-Villatoro-Stuart" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">VILLATORO PERDOMO STUART
                                                    ROMEO</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/gt/21Villatoro-Stuart.jpg"
                                                            alt="VILLATORO PERDOMO, STUART ROMEO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Licenciado en Economía, Universidad Rafael Landivar; Magister en
                                                    Finanzas, Universidad Rafael Landivar; Estudios de Postgrado en
                                                    Inversión Pública en la Korea University de Seúl, Corea; </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Asesor del Presidente de la República de Guatemala en la Secretaria
                                                    Privada de la Presidencia; Diputado al Parlamento Centroamericano
                                                    para el periodo 2024-2028. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 21 /gt Villatoro-Stuart-->

                <!--inicio 22 /gt Zuleta-Marta-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/gt/22Zuleta-Marta.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">ZULETA SANTOS DE GONZÁLEZ MARTA LIDIA</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Zuleta-Marta">Ver informacion</button>
                        <div class="modal fade bd-Zuleta-Marta" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">ZULETA SANTOS DE GONZÁLEZ
                                                    MARTA LIDIA</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Guatemala</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/gt/22Zuleta-Marta.jpg"
                                                            alt="ZULETA SANTOS DE GONZÁLEZ, MARTA LIDIA">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Secretaria Comercial, Colegio Indo – Latino. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputada al Parlamento Centroamericano periodo 2024 -2028. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 22 /gt Zuleta-Marta-->
                <div class="card"></div>
                <div class="card"></div>
                <div class="card"></div>

            </div>


            <div class="section-title-container pt-5 pb-1">
                <h3 class="section-title">{{ "Honduras" | uppercase }}</h3>
            </div>
            <div class="card-deck">
                <!--inicio 1 /hd Jose-Aguilar-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/hd/1Jose-Aguilar.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">JOSÉ LEÓN AGUILAR</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Jose-Aguilar">Ver informacion</button>
                        <div class="modal fade bd-Jose-Aguilar" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">JOSÉ LEÓN AGUILAR</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Honduras</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/hd/1Jose-Aguilar.jpg"
                                                            alt="AGUILAR JOSÉ LEÓN ,">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Administrador de Empresas y Especialista en Administración de
                                                    Servicios de Telecomunicaciones y Bachiller en Ciencias y Letras.
                                                </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Miembro Propietario Consejo Nacional de la Vivienda; Miembro
                                                    Propietario Comisión de Enlace Sociedad Civil y Congreso Nacional;
                                                    Miembro Propietario Comisión de Participación Sociedad Civil;
                                                    Diputado al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 1 /hd Jose-Aguilar-->

                <!--inicio 2 /hd Alvarenga-José-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/hd/2Alvarenga-José.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">ALVARENGA CASTELLANOS JOSÉ MARÍA</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Alvarenga-José">Ver informacion</button>
                        <div class="modal fade bd-Alvarenga-José" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">ALVARENGA CASTELLANOS
                                                    JOSÉ MARÍA</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Honduras</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/hd/2Alvarenga-José.jpg"
                                                            alt="ALVARENGA CASTELLANOS, JOSÉ MARÍA">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Bachiller en Ciencias y Letras; Pasante de la Carrera de Agronomía.
                                                </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Militancia en el PAC, Coordinador Departamental del Departamento de
                                                    El Paraíso, Diputado al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 2 /hd Alvarenga-José-->

                <!--inicio 3 /hd Alvarez-Ricardo-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/hd/3Alvarez-Ricardo.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">ALVAREZ ARIAS RICARDO ANTONIO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Alvarez-Ricardo">Ver informacion</button>
                        <div class="modal fade bd-Alvarez-Ricardo" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">ALVAREZ ARIAS RICARDO
                                                    ANTONIO</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Honduras</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/hd/3Alvarez-Ricardo.jpg"
                                                            alt="ALVAREZ ARIAS, RICARDO ANTONIO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Licenciado en Administración de Empresas, University of Saint Thomas
                                                    Houston, Texas; Maestría en Administración de Empresas, University
                                                    of Miami, Florida. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Designado Presidencial en el periodo 2014 – 2022; Alcalde Municipal
                                                    del Distrito Central en los periodos 2006 – 2014; Ministro
                                                    Coordinador Sectorial de Gobernabilidad y Descentralización;
                                                    Presidente de la Comisión Nacional Pro Instalaciones Deportivas
                                                    CONAPID; Diputado al Congreso Nacional; Presidente del Comité
                                                    Central del Partido Nacional de Honduras; Ministro – Secretario
                                                    Privado del Ex Presidente Ricardo Maduro Joest; Diputado al
                                                    Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 3 /hd Alvarez-Ricardo-->

                <!--inicio 4 /hd Argueta-Olman-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/hd/4Argueta-Olman.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">ARGUETA TURCIOS OLMAN RENÉ</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Argueta-Olman">Ver informacion</button>
                        <div class="modal fade bd-Argueta-Olman" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">ARGUETA TURCIOS OLMAN
                                                    RENÉ</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Honduras</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/hd/4Argueta-Olman.jpg"
                                                            alt="ARGUETA TURCIOS, OLMAN RENÉ">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Ingeniero en Sistemas, Universidad Tecnológica Centroamericana,
                                                    Tegucigalpa M.D.C. Honduras. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Asistente Ejecutivo del Despacho Ministerial en la Secretaría de
                                                    Estado en el Despacho de Infraestructura y Servicios Públicos;
                                                    Subsecretario de Relación y Coordinación Gremial de Casa
                                                    Presidencial; Diputado al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 4 /hd Argueta-Olman-->

                <!--inicio 5 /hd Bu-Miriam-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/hd/5Bu-Miriam.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">BU OSORTO MIRIAM HORTENCIA</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Bu-Miriam">Ver informacion</button>
                        <div class="modal fade bd-Bu-Miriam" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">BU OSORTO MIRIAM
                                                    HORTENCIA</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Honduras</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top" src="assets/img/HD/hd/5Bu-Miriam.jpg"
                                                            alt="BU OSORTO, MIRIAM HORTENCIA">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Secretaria Comercial. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Miembro del Partido Alianza Patriótica Hondureña (La Alianza),
                                                    Diputada al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 5 /hd Bu-Miriam-->

            </div>
            <div class="card-deck">

                <!--inicio 6 /hd Castellanos-Walter-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/hd/6Castellanos-Walter.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">CASTELLANOS SAUCEDA WALTER REINIERY</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Castellanos-Walter">Ver informacion</button>
                        <div class="modal fade bd-Castellanos-Walter" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">CASTELLANOS SAUCEDA
                                                    WALTER REINIERY</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Honduras</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/hd/6Castellanos-Walter.jpg"
                                                            alt="CASTELLANOS SAUCEDA, WALTER REINIERY">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 6 /hd Castellanos-Walter-->

                <!--inicio 7 /hd Cruz-Augusto-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/hd/7Cruz-Augusto.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">CRUZ ASENSIO AUGUSTO DOMINGO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Cruz-Augusto">Ver informacion</button>
                        <div class="modal fade bd-Cruz-Augusto" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">CRUZ ASENSIO AUGUSTO
                                                    DOMINGO</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Honduras</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/hd/7Cruz-Augusto.jpg"
                                                            alt="CRUZ ASENSIO, AUGUSTO DOMINGO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Sociólogo. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Vicepresidente, Presidente de la Comisión de Medio Ambiente y Cambio
                                                    Climático, Secretario de la Comisión Electoral, Miembro de la
                                                    Comisión de Presupuesto; Miembro de la Selección de Funcionarios
                                                    Electos, Miembro de la Comisión de Seguridad Social, Miembro de las
                                                    Comisiones Legislativas del Café del Congreso Nacional de la
                                                    República de Honduras; Jefe de Bancada Legislativa del Partido
                                                    Centro Social Cristiano “Va, Movimiento Solidario” VAMOS; Enlace
                                                    Social Civil, Dictaminador de las Leyes de Seguridad, Medio
                                                    Ambiente, Creador de Leyes; Representante Nacional en cinco Cumbres
                                                    Mundiales sobre el Cambio Climático y Miembro de Comisiones
                                                    Especiales en Representación de Honduras en distintos eventos
                                                    internacionales. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 7 /hd Cruz-Augusto-->

                <!--inicio 8 /hd Godoy-José-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/hd/8Godoy-José.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">GODOY URBINA JOSÉ OCTAVIO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Godoy-José">Ver informacion</button>
                        <div class="modal fade bd-Godoy-José" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">GODOY URBINA JOSÉ OCTAVIO
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Honduras</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top" src="assets/img/HD/hd/8Godoy-José.jpg"
                                                            alt="GODOY URBINA, JOSÉ OCTAVIO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Oficial de Protocolo en el Congreso Nacional de Honduras 2002 – 2006.
                                                </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputado al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 8 /hd Godoy-José-->

                <!--inicio 9 /hd Jiménez-Jorge-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/hd/9Jiménez-Jorge.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">JIMÉNEZ REYES JORGE FERNANDO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Jiménez-Jorge">Ver informacion</button>
                        <div class="modal fade bd-Jiménez-Jorge" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">JIMÉNEZ REYES JORGE
                                                    FERNANDO</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Honduras</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/hd/9Jiménez-Jorge.jpg"
                                                            alt="JIMÉNEZ REYES, JORGE FERNANDO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Pasante de la Carrera de Derecho, Universidad de CEUTEC. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Sub Secretario General del Partido Frente Amplio (El Frente);
                                                    Diputado al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 9 /hd Jiménez-Jorge-->

                <!--inicio 10 /hd Licona-Nedis-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/hd/10Licona-Nedis.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">LICONA REYES NEDIS ADRIÁN</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Licona-Nedis">Ver informacion</button>
                        <div class="modal fade bd-Licona-Nedis" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">LICONA REYES NEDIS ADRIÁN
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Honduras</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/hd/10Licona-Nedis.jpg"
                                                            alt="LICONA REYES, NEDIS ADRIÁN">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Licenciatura en Ciencias Sociales; Universidad Pedagógica Nacional,
                                                    Francisco Morazán; Docente Educación Media, Instituto Polivalente
                                                    Germania. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 10 /hd Licona-Nedis-->

            </div>
            <div class="card-deck">

                <!--inicio 12 /hd Maldonado-Yehring-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/hd/12Maldonado-Yehring.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">MALDONADO YEHRING ROBERTO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Maldonado-Yehring">Ver informacion</button>
                        <div class="modal fade bd-Maldonado-Yehring" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">MALDONADO YEHRING ROBERTO
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Honduras</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/hd/12Maldonado-Yehring.jpg"
                                                            alt="MALDONADO, YEHRING ROBERTO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputado al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 12 /hd Maldonado-Yehring-->

                <!--inicio 13 /hd Manzanares-Carlos-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/hd/13Manzanares-Carlos.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">MANZANARES CABRERA CARLOS HUMBERTO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Manzanares-Carlos">Ver informacion</button>
                        <div class="modal fade bd-Manzanares-Carlos" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">MANZANARES CABRERA CARLOS
                                                    HUMBERTO</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Honduras</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/hd/13Manzanares-Carlos.jpg"
                                                            alt="MANZANARES CABRERA, CARLOS HUMBERTO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Bachiller en Ciencias y Letras en el Instituto Técnico Departamental
                                                    Terencio Sierra </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Regidor Municipal de Nacaome, Valle, Honduras en los periodos 1998 –
                                                    2000 y 2006 – 2014; Presidente del Partido Demócrata Cristiano de
                                                    Honduras; Coordinador de Campañas Departamento de Valle del Partido
                                                    Demócrata Cristiano de Honduras en el periodo 1998 – 2021.
                                                    Candidato a Alcalde.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 13 /hd Manzanares-Carlos-->

                <!--inicio 14 /hd Mencías-Nelsy-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/hd/14Mencías-Nelsy.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">MENCÍAS MARTÍNEZ NELSY YOLANY</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Mencías-Nelsy">Ver informacion</button>
                        <div class="modal fade bd-Mencías-Nelsy" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">MENCÍAS MARTÍNEZ NELSY
                                                    YOLANY</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Honduras</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/hd/14Mencías-Nelsy.jpg"
                                                            alt="MENCÍAS MARTÍNEZ, NELSY YOLANY">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Licenciada en Administración de Empresas Agropecuarias, Universidad
                                                    Nacional Autónoma de Honduras; Técnico Universitario en Educación
                                                    Básica, Universidad Pedagógica Nacional Francisco Morazán;
                                                    Licenciada en Educación Básica, Universidad Pedagógica Nacion </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputada al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 14 /hd Mencías-Nelsy-->

                <!--inicio 15 /hd Navarro-Jenny-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/hd/15Navarro-Jenny.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">NAVARRO OSORIO JENNY ELIZETH</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Navarro-Jenny">Ver informacion</button>
                        <div class="modal fade bd-Navarro-Jenny" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">NAVARRO OSORIO JENNY
                                                    ELIZETH</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Honduras</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/hd/15Navarro-Jenny.jpg"
                                                            alt="NAVARRO OSORIO, JENNY ELIZETH">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Sub Gerente de Recursos Humanos en la Secretaría de Agricultura y
                                                    Ganadería. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputada al Parlamento Centroamericano </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 15 /hd Navarro-Jenny-->

            </div>
            <div class="card-deck">

                <!--inicio 16 /hd Orellana-Angel-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/hd/no-imagen.PNG" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">ORELLANA MERCADO ANGEL EDMUNDO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Orellana-Angel">Ver informacion</button>
                        <div class="modal fade bd-Orellana-Angel" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">ORELLANA MERCADO ANGEL
                                                    EDMUNDO</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Honduras</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top" src="assets/img/HD/hd/no-imagen.PNG"
                                                            alt="ORELLANA MERCADO, ANGEL EDMUNDO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Abogado. Premio Nacional de Ciencia Otorgado por el Estado de
                                                    Honduras y el “Juris Máxima Pluma de Oro” por el Colegio de Abogados
                                                    de Honduras; Autor de libros sobre Derecho Administrativo y Derecho
                                                    Constitucional; Consultoría Jurídica y el Notariado. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Magistrado Propietario de la Corte de Apelaciones de lo Contencioso;
                                                    Fiscal General de la República; Representante Permanente en la
                                                    Organización de las Naciones Unidas; Diputado Propietario Francisco
                                                    Morazán, Secretario de Estado en los Despachos de Gobernación y
                                                    Justicia, Relaciones Exteriores y Defensa; Diputado al Parlamento
                                                    Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 16 /hd Orellana-Angel-->

                <!--inicio 17 /hd Pineda-Engels-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/hd/17Pineda-Engels.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">PINEDA GARCÍA ENGELS MARTÍN</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Pineda-Engels">Ver informacion</button>
                        <div class="modal fade bd-Pineda-Engels" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">PINEDA GARCÍA ENGELS
                                                    MARTÍN</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Honduras</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/hd/17Pineda-Engels.jpg"
                                                            alt="PINEDA GARCÍA, ENGELS MARTÍN">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Doctor en Medicina, Universidad Nacional Autónoma de Honduras. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Coordinador Nacional de Organización Estudiantil Universitaria;
                                                    Integrante de la Junta Directiva de la Facultad de Ciencias Medicas
                                                    de la UNAH, Coordinador del Movimiento Ciudadano Francisco Morazán,
                                                    Miembro Fundador, Presidente y Secretario General del Partido
                                                    Unificación Democrática UD; Miembro del Consejo Consultivo Electoral
                                                    del TSE en las elecciones 2009, 2013 y 2017; Diputado al Parlamento
                                                    Centroamericano en los periodos 2011 – 2016, 2016 – 2021 y 2021 –
                                                    2025. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 17 /hd Pineda-Engels-->

                <!--inicio 18 /hd Sorto-Mario-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/hd/18Sorto-Mario.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">SORTO DERAS MARIO HERNÁN</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Sorto-Mario">Ver informacion</button>
                        <div class="modal fade bd-Sorto-Mario" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">SORTO DERAS MARIO HERNÁN
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Honduras</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/hd/18Sorto-Mario.jpg"
                                                            alt="SORTO DERAS, MARIO HERNÁN">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Perito Mercantil y Contador Público, Diplomado en Gestión
                                                    Empresarial, Técnico Universitario en Administración de Empresas y
                                                    Abogado. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputado al Congreso Nacional de Honduras y Diputado al Parlamento
                                                    Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 18 /hd Sorto-Mario-->

                <!--inicio 19 /hd Villatoro-Alejandro-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/hd/19Villatoro-Alejandro.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">VILLATORO AGUILAR ALEJANDRO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Villatoro-Alejandro">Ver informacion</button>
                        <div class="modal fade bd-Villatoro-Alejandro" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">VILLATORO AGUILAR
                                                    ALEJANDRO</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Honduras</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/hd/19Villatoro-Alejandro.jpg"
                                                            alt="VILLATORO AGUILAR, ALEJANDRO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputado Suplente Congreso Nacional de Honduras en el periodo
                                                    2006-2010 y Diputado al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 19 /hd Villatoro-Alejandro-->

                <!--inicio 20 /hd Zelaya-Rodimiro-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/hd/20Zelaya-Rodimiro.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">ZELAYA CORTÉS RODIMIRO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Zelaya-Rodimiro">Ver informacion</button>
                        <div class="modal fade bd-Zelaya-Rodimiro" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">ZELAYA CORTÉS RODIMIRO
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Honduras</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/hd/20Zelaya-Rodimiro.jpg"
                                                            alt="ZELAYA CORTÉS, RODIMIRO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Licenciado en Administración, Universidad Tecnológica Centroamericana
                                                    UNITEC; Subteniente de Infantería en Reserva, Liceo Militar del
                                                    Norte. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Director de Programas Especiales, Alcaldía Municipal del Distrito
                                                    Central periodo 2002 – 2003; Secretario Privado del Alcalde,
                                                    Alcaldía Municipal del Distrito Central periodo 2004 – 2006;
                                                    Secretario Privado del Ministro en la Secretaría de Obras Públicas
                                                    periodo 2010 – 2012; Participación en la Comisión Nacional Electoral
                                                    del Partido Nacional de Honduras, Elecciones Internas 2001;
                                                    Organización de la Juventud del Distrito Central del Partido
                                                    Nacional de Honduras 2000 – 2002. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 20 /hd Zelaya-Rodimiro-->


            </div>

            <div class="section-title-container pt-5 pb-1">
                <h3 class="section-title">{{ "Nicaragua" | uppercase }}</h3>
            </div>
            <div class="card-deck">
                <!--inicio 1 /ni Alvarado-José-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/ni/1Alvarado-José.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">ALVARADO CORREA JOSÉ ANTONIO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Alvarado-José">Ver informacion</button>
                        <div class="modal fade bd-Alvarado-José" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">ALVARADO CORREA JOSÉ
                                                    ANTONIO</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Nicaragua</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/ni/1Alvarado-José.jpg"
                                                            alt="ALVARADO CORREA, JOSÉ ANTONIO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Doctorado en Derecho; Universidad Centroamericana; Maestría en
                                                    Economía, Universidad Degli Studi, Roma; Cursos de Postgrado en
                                                    Finanzas y Economía Internacional, Universidad de Pennsylvania;
                                                    Maestría en Derecho Comparado, Facultad de Derecho de la Univer </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Embajador de Nicaragua ante las Naciones Unidas 1977-1979; Ministro
                                                    de Gobernación 1997-1998; Ministro de Educación, Cultura y Deportes
                                                    1998-1999; Ministro de Defensa 1999-2000; Secretario de Presidencia
                                                    para los Asuntos Políticos 2002-2003; Ministro de Salud 2003-2004;
                                                    Presidente del Parlamento Centroamericano 2015-2016. Diputado al
                                                    Parlamento Centroamericano por tres períodos consecutivos:
                                                    2012-2016, 2017-2021 y 2022-2027. Presidente de la Comisión de
                                                    Asuntos Jurídicos e Institucionalidad Regional; Presidente de la
                                                    Comisión Especial de la Redacciónde la Reforma al Tratado
                                                    Constitutivo del PARLACEN; Tres veces Presidente de la Comisión de
                                                    Turismo; Vicepresidente de la Comisión Especial de Redacción del
                                                    Reglamento Interno del Parlamento Centroamericano 2022 y Presidente
                                                    de la Delegación de EuroLat, así como Miembro de la Mesa Directiva.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 1 /ni Alvarado-José-->

                <!--inicio 2 /ni Betanco-Winston-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/ni/2Betanco-Winston.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">BETANCO BLANDÓN WINSTON EDGARDO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Betanco-Winston">Ver informacion</button>
                        <div class="modal fade bd-Betanco-Winston" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">BETANCO BLANDÓN WINSTON
                                                    EDGARDO</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Nicaragua</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/ni/2Betanco-Winston.jpg"
                                                            alt="BETANCO BLANDÓN, WINSTON EDGARDO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Licenciado en Economía con Mención en Economía Agrícola, UNAN –
                                                    Managua; Docente Universitario. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputado al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 2 /ni Betanco-Winston-->

                <!--inicio 3 /ni Blanco-Aida-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/ni/3Blanco-Aida.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">BLANCO TALAVERA AIDA ESTER</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Blanco-Aida">Ver informacion</button>
                        <div class="modal fade bd-Blanco-Aida" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">BLANCO TALAVERA AIDA
                                                    ESTER</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Nicaragua</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/ni/3Blanco-Aida.jpg"
                                                            alt="BLANCO TALAVERA, AIDA ESTER">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Secretaría General, Sindicato FETSALUD Silais León; Presidenta de la
                                                    AEN Departamental León; Responsable de Enfermería, Silais León;
                                                    Subdirectora, Silais León. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Actual Secretaria del PARLACEN por el Estado de Nicaragua para el
                                                    período 2023-2024. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 3 /ni Blanco-Aida-->

                <!--inicio 4 /ni Blandón-Julio-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/ni/4Blandón-Julio.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">BLANDÓN SÁNCHEZ JULIO CÉSAR</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Blandón-Julio">Ver informacion</button>
                        <div class="modal fade bd-Blandón-Julio" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">BLANDÓN SÁNCHEZ JULIO
                                                    CÉSAR</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Nicaragua</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/ni/4Blandón-Julio.jpg"
                                                            alt="BLANDÓN SÁNCHEZ, JULIO CÉSAR">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Licenciado en Administración de Empresas, Universidad Nacional
                                                    Autónoma de Nicaragua; Egresado de la Carrera de Licenciatura en
                                                    Derecho, Universidad Internacional de Integración de América Latina.
                                                </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Asesor del Ministro para Asuntos de la Resistencia Nicaragüense en el
                                                    Ministerio de Gobernación 2007; Presidente Nacional y Representante
                                                    Legal del Partido Resistencia Nicaragüense 2008; Diputado al
                                                    Parlamento Centroamericano por tres períodos consecutivos:
                                                    2012-2016, 2017-2021 y 2022-2027. Vicepresidente 2019-2020; ocupó el
                                                    cargo de Secretario de la Comisión Jurídica e Institucionalidad
                                                    Regional. Integrante de Comisión de Asuntos Políticos. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 4 /ni Blandón-Julio-->

                <!--inicio 5 /ni Colindres-Larissa-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/ni/5Colindres-Larissa.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">COLINDRES MALDONADO LARISSA REGINA</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Colindres-Larissa">Ver informacion</button>
                        <div class="modal fade bd-Colindres-Larissa" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">COLINDRES MALDONADO
                                                    LARISSA REGINA</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Nicaragua</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/ni/5Colindres-Larissa.jpg"
                                                            alt="COLINDRES MALDONADO, LARISSA REGINA">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 5 /ni Colindres-Larissa-->

            </div>
            <div class="card-deck">

                <!--inicio 6 /ni Francis-Sidney-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/ni/6Francis-Sidney.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">FRANCIS MARTIN SIDNEY ORLINGTON</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Francis-Sidney">Ver informacion</button>
                        <div class="modal fade bd-Francis-Sidney" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">FRANCIS MARTIN SIDNEY
                                                    ORLINGTON</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Nicaragua</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/ni/6Francis-Sidney.jpg"
                                                            alt="FRANCIS MARTIN, SIDNEY ORLINGTON">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 6 /ni Francis-Sidney-->

                <!--inicio 7 /ni Galeano-Elida-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/ni/7Galeano-Elida.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">GALEANO CORNEJO ELIDA MARÍA</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Galeano-Elida">Ver informacion</button>
                        <div class="modal fade bd-Galeano-Elida" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">GALEANO CORNEJO ELIDA
                                                    MARÍA</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Nicaragua</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/ni/7Galeano-Elida.jpg"
                                                            alt="GALEANO CORNEJO, ELIDA MARÍA">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Fundadora, Líder Política de la Alianza Resistencia Nicaragüense
                                                    ARNIC; Firmante de la Triple Alianza del 18 de septiembre de 1996;
                                                    Presidenta Nacional de la Asociación Resistencia Nicaragüense Israel
                                                    Galeano Comandante (Franklin) ARNIG; Diputada Nacional 2007 – 2013;
                                                    Diputada al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 7 /ni Galeano-Elida-->

                <!--inicio 8 /ni Galeano-Meylin-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/ni/8Galeano-Meylin.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">GALEANO VILLACHICA MEYLIN OLMARA</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Galeano-Meylin">Ver informacion</button>
                        <div class="modal fade bd-Galeano-Meylin" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">GALEANO VILLACHICA MEYLIN
                                                    OLMARA</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Nicaragua</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/ni/8Galeano-Meylin.jpg"
                                                            alt="GALEANO VILLACHICA, MEYLIN OLMARA">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Estudios Universitarios en Administración de Empresas, Universidad
                                                    UPONIC, Muelle de los Bueyes, Región Autónoma Atlántico Sur. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Fundadora del Partido Alternativa Cristiana; Miembro de la Junta
                                                    Directiva de Alternativa Cristiana en la Departamental 1995;
                                                    Presidenta Municipal de Villa Sandino en 1998; Representante Legal
                                                    Departamental y Municipal de Alternativa Cristiana 2018; Diputada al
                                                    Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 8 /ni Galeano-Meylin-->

                <!--inicio 9 /ni Garcia-Carlos-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/ni/9Garcia-Carlos.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">GARCIA BONILLA CARLOS SALOMÓN</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Garcia-Carlos">Ver informacion</button>
                        <div class="modal fade bd-Garcia-Carlos" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">GARCIA BONILLA CARLOS
                                                    SALOMÓN</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Nicaragua</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/ni/9Garcia-Carlos.jpg"
                                                            alt="GARCIA BONILLA, CARLOS SALOMÓN">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Miembro del Partido Liberal Constitucionalista PLC 1992-1999;
                                                    Asamblea Nacional, Diputado Propietario 1997-2001; Fundador y
                                                    Secretario Nacional del Partido Movimiento Salvación Liberal MSL
                                                    1999-2006; Asamblea Nacional, Diputado Propietario 2007-2011;
                                                    Secretario Nacional Partido Alianza Liberal Nicaragüense ALN
                                                    2006-2022; Diputado al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 9 /ni Garcia-Carlos-->

                <!--inicio 10 /ni Morales-Jaime-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/ni/10Morales-Jaime.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">MORALES CARAZO JAIME RENÉ</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Morales-Jaime">Ver informacion</button>
                        <div class="modal fade bd-Morales-Jaime" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">MORALES CARAZO JAIME RENÉ
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Nicaragua</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/ni/10Morales-Jaime.jpg"
                                                            alt="MORALES CARAZO, JAIME RENÉ">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 10 /ni Morales-Jaime-->

            </div>
            <div class="card-deck">

                <!--inicio 11 /ni Ortega-Guillermo-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/ni/11Ortega-Guillermo.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">ORTEGA REYES GUILLERMO DANIEL</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Ortega-Guillermo">Ver informacion</button>
                        <div class="modal fade bd-Ortega-Guillermo" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">ORTEGA REYES GUILLERMO
                                                    DANIEL</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Nicaragua</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/ni/11Ortega-Guillermo.jpg"
                                                            alt="ORTEGA REYES, GUILLERMO DANIEL">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Licenciado en Teología, Graduado en Carolina del Norte Estados
                                                    Unidos. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Ex Presidente del PARLACEN período 2021-2022; Actual Vicepresidente
                                                    2023-2024, cargo que también en el 2022-2023 y en el período
                                                    2011-2012;Coordinador de la Asamblea Parlamentaria
                                                    Euro-Latinoamericana (EuroLat) y Presidente de la Comisión
                                                    Permanente de Asuntos Políticos, de Seguridad y de Derechos Humanos
                                                    de La Asamblea Parlamentaria Euro-Latinoamericana (EuroLat)
                                                    2020-2021; Co Vice-Presidente de la Comisión de Asuntos Económicos,
                                                    Financieros y Comerciales, de La Asamblea Parlamentaria
                                                    Euro-Latinoamericana (EuroLat) 2019-2020; Presidente de la Comisión
                                                    de Relaciones Internacionales y Asunto Migratorio del Parlamento
                                                    Centroamericano 2019-2020; Vicepresidente de la Comisión de
                                                    Relaciones Internacionales y Asuntos Migratorios del Parlamento
                                                    Centroamericano 2014-2019; Presidente de la Comisión de Relaciones
                                                    Internacionales y Asuntos Migratorios del Parlamento Centroamericano
                                                    2013-2014. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 11 /ni Ortega-Guillermo-->

                <!--inicio 12 /ni Reyes-Socorro-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/ni/12Reyes-Socorro.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">REYES LARA SOCORRO JACINTA</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Reyes-Socorro">Ver informacion</button>
                        <div class="modal fade bd-Reyes-Socorro" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">REYES LARA SOCORRO
                                                    JACINTA</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Nicaragua</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/ni/12Reyes-Socorro.jpg"
                                                            alt="REYES LARA, SOCORRO JACINTA">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Estadística en la UNAN </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Miembro activo del Partido Liberal Constitucionalista (PLC) como
                                                    Secretaria de la Mujer en la Directiva de Barrios; Vocal de la
                                                    Directiva Distrito II, Fiscal; miembro de mesa y encargada del fax
                                                    de la JRV en once elecciones Nacionales y Regionales; Fiscal
                                                    Nacional de Cedulación Suplente; Fiscal Nacional Propietario;
                                                    Miembro de la Secretaría Nacional de la Mujer y Presidente de la
                                                    Comisión Electoral; Diputada al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 12 /ni Reyes-Socorro-->

                <!--inicio 13 /ni Rosales-Ludendorff-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/ni/13Rosales-Ludendorff.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">ROSALES HUECK LUDENDORFF ENRIQUE</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Rosales-Ludendorff">Ver informacion</button>
                        <div class="modal fade bd-Rosales-Ludendorff" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">ROSALES HUECK LUDENDORFF
                                                    ENRIQUE</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Nicaragua</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/ni/13Rosales-Ludendorff.jpg"
                                                            alt="ROSALES HUECK, LUDENDORFF ENRIQUE">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Cargo de Formación Política en el Municipio de Chinandega por el
                                                    Partido Liberal Constitucionalista 2004 y 2006; Jefe de Ruta del
                                                    Partido Liberal Constitucionalista 2006; Secretario Departamental de
                                                    la Juventud del Partido Liberal Constitucionalista 2011 -2017;
                                                    Diputado al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 13 /ni Rosales-Ludendorff-->

                <!--inicio 14 /ni Ruíz-Vidal-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/ni/14Ruíz-Vidal.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">RUÍZ CERDA VIDAL ANTONIO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Ruíz-Vidal">Ver informacion</button>
                        <div class="modal fade bd-Ruíz-Vidal" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">RUÍZ CERDA VIDAL ANTONIO
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Nicaragua</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/ni/14Ruíz-Vidal.jpg"
                                                            alt="RUÍZ CERDA, VIDAL ANTONIO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 14 /ni Ruíz-Vidal-->

                <!--inicio 15 /ni Tijerino-Doris-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/ni/15Tijerino-Doris.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">TIJERINO HASLAM DORIS MARÍA</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Tijerino-Doris">Ver informacion</button>
                        <div class="modal fade bd-Tijerino-Doris" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">TIJERINO HASLAM DORIS
                                                    MARÍA</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Nicaragua</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/ni/15Tijerino-Doris.jpg"
                                                            alt="TIJERINO HASLAM, DORIS MARÍA">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 15 /ni Tijerino-Doris-->

            </div>
            <div class="card-deck">

                <!--inicio 16 /ni Trujillo-Aleyda-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/ni/16Trujillo-Aleyda.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">TRUJILLO RUIZ ALEYDA DE LA CRUZ</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Trujillo-Aleyda">Ver informacion</button>
                        <div class="modal fade bd-Trujillo-Aleyda" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">TRUJILLO RUIZ ALEYDA DE
                                                    LA CRUZ</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Nicaragua</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/ni/16Trujillo-Aleyda.jpg"
                                                            alt="TRUJILLO RUIZ, ALEYDA DE LA CRUZ">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 16 /ni Trujillo-Aleyda-->

                <!--inicio 17 /ni Trujillo-Margine-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/ni/17Trujillo-Margine.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">TRUJILLO RUIZ MARGINE DEL CARMEN</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Trujillo-Margine">Ver informacion</button>
                        <div class="modal fade bd-Trujillo-Margine" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">TRUJILLO RUIZ MARGINE DEL
                                                    CARMEN</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Nicaragua</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/ni/17Trujillo-Margine.jpg"
                                                            alt="TRUJILLO RUIZ, MARGINE DEL CARMEN">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 17 /ni Trujillo-Margine-->

                <!--inicio 18 /ni Umaña-Evile-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/ni/18Umaña-Evile.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">UMAÑA OLIVAS EVILE DEL SOCORRO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Umaña-Evile">Ver informacion</button>
                        <div class="modal fade bd-Umaña-Evile" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">UMAÑA OLIVAS EVILE DEL
                                                    SOCORRO</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Nicaragua</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/ni/18Umaña-Evile.jpg"
                                                            alt="UMAÑA OLIVAS, EVILE DEL SOCORRO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Auxiliar de Enfermería; Abogada y Notaria. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Militante de la II Promoción de FSLN 1980; Secretaría Política
                                                    Telica, Quezalguaque 1981; Secretaría Política de Organización del
                                                    FSLN 1982; Atención a Sectores de Trabajadores (atención Política
                                                    ideológica del Regional FSLN) 1983; Diputada Suplente Nacional y
                                                    Diputada al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 18 /ni Umaña-Evile-->

                <!--inicio 19 /ni White-Carla-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/ni/19White-Carla.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">WHITE HODGSON CARLA EVIS</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-White-Carla">Ver informacion</button>
                        <div class="modal fade bd-White-Carla" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">WHITE HODGSON CARLA EVIS
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Nicaragua</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/ni/19White-Carla.jpg"
                                                            alt="WHITE HODGSON, CARLA EVIS">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Ministerio de Transporte 1982; Ministerio de Cooperación Externa
                                                    1986; ENIR 1994; Gobierno Regional Caribe Sur 1995; Consejo Regional
                                                    Caribe Sur 1997 – 2021; Presidenta del Partido IndígenaMultiétnico
                                                    (PIM); Diputada al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 19 /ni White-Carla-->

                <!--inicio 20 /ni Zepeda-José-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/ni/20Zepeda-José.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">ZEPEDA LÓPEZ JOSÉ ANTONIO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Zepeda-José">Ver informacion</button>
                        <div class="modal fade bd-Zepeda-José" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">ZEPEDA LÓPEZ JOSÉ ANTONIO
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Nicaragua</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/ni/20Zepeda-José.jpg"
                                                            alt="ZEPEDA LÓPEZ, JOSÉ ANTONIO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Licenciado en Ciencias de la Educación con mención en la especialidad
                                                    de Física; Licenciado en Administración de Empresas; Postgrados en
                                                    Psicología, Políticas Educativas, Pedagogía y Marxismo –Leninismo.
                                                </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputado al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 20 /ni Zepeda-José-->

            </div>

            <div class="section-title-container pt-5 pb-1">
                <h3 class="section-title">{{ "Panamá" | uppercase }}</h3>
            </div>
            <div class="card-deck">
                <!--inicio 1 /pa Aparicio-Hector-->
                <div class="card"> x </div>
                <!-- Fin 1 /pa Aparicio-Hector-->

                <!--inicio 2 /pa Beitia-Uarren-->
                <div class="card"> x </div>
                <!-- Fin 2 /pa Beitia-Uarren-->

                <!--inicio 3 /pa Burillo-Giselle-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/pa/3Burillo-Giselle.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">BURILLO SAIZ GISELLE DE LOURDES</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Burillo-Giselle">Ver informacion</button>
                        <div class="modal fade bd-Burillo-Giselle" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">BURILLO SAIZ GISELLE DE
                                                    LOURDES</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Panamá</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/pa/3Burillo-Giselle.jpg"
                                                            alt="BURILLO SAIZ, GISELLE DE LOURDES">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 3 /pa Burillo-Giselle-->

                <!--inicio 4 /pa Castillero-Manuel-->
                <div class="card"> x </div>
                
                <!-- Fin 4 /pa Castillero-Manuel-->

                <!--inicio 5 /pa Cerrud-Amado-->
                <div class="card"> x </div>
                <!-- Fin 5 /pa Cerrud-Amado-->

            </div>
            <div class="card-deck">

                <!--inicio 6 /pa Cortez-Dorindo-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/pa/6Cortez-Dorindo.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">CORTEZ MARCIAGA DORINDO JAYAN</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Cortez-Dorindo">Ver informacion</button>
                        <div class="modal fade bd-Cortez-Dorindo" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">CORTEZ MARCIAGA DORINDO
                                                    JAYAN</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Panamá</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/pa/6Cortez-Dorindo.jpg"
                                                            alt="CORTEZ MARCIAGA, DORINDO JAYAN">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Licenciado en Derecho y Ciencia Política, Facultad de Derecho,
                                                    Universidad de Panamá; Maestría en Historia de Poder y las
                                                    Relaciones Internacionales de la Universidad de Costa Rica;
                                                    Licenciado en Filosofía y Letras, con especialidad en Geografía e
                                                    Histor </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Diputado de la República de Panamá periodo 2004-2009; Diputado del
                                                    Parlamento Centroamericano por tres periodos; Presidente del
                                                    Parlamento Centroamericano periodo 2011; Vicepresidente del
                                                    Parlamento Centroamericano periodo 2011-2012; Vicepresidente del
                                                    Parlamento Centroamericano 2023-2024; Co-Vicepresidente del
                                                    Componente Europeo-Latinoamericano de EUROLAT. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 6 /pa Cortez-Dorindo-->

                <!--inicio 7 /pa De-Rubén-->
                <div class="card"> x </div>
                <!-- Fin 7 /pa De-Rubén-->

                <!--inicio 8 /pa García-Juan-->
                <div class="card"> x </div>
                <!-- Fin 8 /pa García-Juan-->

                <!--inicio 9 /pa González-Rachel-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/pa/9González-Rachel.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">GONZÁLEZ SANTOS DE RODRÍGUEZ RACHEL</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-González-Rachel">Ver informacion</button>
                        <div class="modal fade bd-González-Rachel" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">GONZÁLEZ SANTOS DE
                                                    RODRÍGUEZ RACHEL</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Panamá</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/pa/9González-Rachel.jpg"
                                                            alt="GONZÁLEZ SANTOS DE RODRÍGUEZ, RACHEL">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 9 /pa González-Rachel-->

                <!--inicio 10 /pa Muñoz-Jackeline-->
                <div class="card"> x </div>
                <!-- Fin 10 /pa Muñoz-Jackeline-->

            </div>
            <div class="card-deck">

                <!--inicio 11 /pa Noriega-Sandra-->
                <div class="card"> x </div>
                <!-- Fin 11 /pa Noriega-Sandra-->

                <!--inicio 12 /pa Outten-Carlos-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/pa/12Outten-Carlos.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">OUTTEN GONZÁLEZ CARLOS RODOLFO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Outten-Carlos">Ver informacion</button>
                        <div class="modal fade bd-Outten-Carlos" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">OUTTEN GONZÁLEZ CARLOS
                                                    RODOLFO</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Panamá</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/pa/12Outten-Carlos.jpg"
                                                            alt="OUTTEN GONZÁLEZ, CARLOS RODOLFO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 12 /pa Outten-Carlos-->

                <!--inicio 13 /pa Palacios-Julio-->
                <div class="card"> x </div>
                <!-- Fin 13 /pa Palacios-Julio-->

                <!--inicio 14 /pa Ramos-José-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/pa/14Ramos-José.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">RAMOS REYES JOSÉ NARCISO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Ramos-José">Ver informacion</button>
                        <div class="modal fade bd-Ramos-José" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">RAMOS REYES JOSÉ NARCISO
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Panamá</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/pa/14Ramos-José.jpg"
                                                            alt="RAMOS REYES, JOSÉ NARCISO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Coach Inovat, Escuela de Inteligencia Novita y Bachiller en
                                                    Construcción con Especialización en Soldadura, InstitutoProfesional
                                                    y Técnico. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Asesor del Despacho Superior, Ministerio de Gobierno 2018;Secretario
                                                    Ejecutivo de Gobernabilidad, Ministerio de Gobierno 2014-2017;
                                                    Diputado al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 14 /pa Ramos-José-->

                <!--inicio 15 /pa Salas-Cirilo-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/pa/15Salas-Cirilo.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">SALAS LEMOS CIRILO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Salas-Cirilo">Ver informacion</button>
                        <div class="modal fade bd-Salas-Cirilo" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">SALAS LEMOS CIRILO</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: Panamá</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/pa/15Salas-Cirilo.jpg"
                                                            alt="SALAS LEMOS, CIRILO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Corregidor de Policía Distrito Panamá. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Secretario Provisional de Panamá, Miembro del Directorio Nacional,
                                                    Comité Político periodo 2009 – 2014; Candidato a Diputado Circuito
                                                    8-10 Partido Popular 2009; Secretario Provincia de Panamá periodo
                                                    2005 – 2009; Diputado al Parlamento Centroamericano. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 15 /pa Salas-Cirilo-->

            </div>
            <div class="card-deck">

                <!--inicio 16 /pa Succari-Gilberto-->
                <div class="card"> x </div>
                <!-- Fin 16 /pa Succari-Gilberto-->

                <!--inicio 17 /pa Tuñón-Dalvier-->
                <div class="card"> x </div>
                <!-- Fin 17 /pa Tuñón-Dalvier-->

                <!--inicio 18 /pa Valderrama-Héctor-->
                <div class="card"> x </div>
                <!-- Fin 18 /pa Valderrama-Héctor-->

                <!--inicio 19 /pa Vásquez-Luz-->
                <div class="card"> x </div>
                <!-- Fin 19 /pa Vásquez-Luz-->

                <!--inicio 20 /pa Vásquez-Alcibíades-->
                <div class="card"> x </div>
                <!-- Fin 20 /pa Vásquez-Alcibíades-->

            </div>

            <div class="section-title-container pt-5 pb-1">
                <h3 class="section-title">{{ "República Dominicana" | uppercase }}</h3>
            </div>
            <div class="card-deck">
                <!--inicio 1 /rd Aguirre-Pedro-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/rd/1Aguirre-Pedro.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">AGUIRRE HERNÁNDEZ PEDRO ALEJANDRO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Aguirre-Pedro">Ver informacion</button>
                        <div class="modal fade bd-Aguirre-Pedro" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">AGUIRRE HERNÁNDEZ PEDRO
                                                    ALEJANDRO</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: República dominicana</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/rd/1Aguirre-Pedro.jpg"
                                                            alt="AGUIRRE HERNÁNDEZ, PEDRO ALEJANDRO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 1 /rd Aguirre-Pedro-->

                <!--inicio 2 /rd Alfonseca-José-->
                <div class="card"> x </div>
                <!-- Fin 2 /rd Alfonseca-José-->

                <!--inicio 3 /rd Báez-Higinio-->
                <div class="card"> x </div>
                <!-- Fin 3 /rd Báez-Higinio-->

                <!--inicio 4 /rd Casado-Bienvenido-->
                <div class="card"> x </div>
                <!-- Fin 4 /rd Casado-Bienvenido-->

                <!--inicio 5 /rd Cedeño-Margarita-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/rd/no-imagen.PNG" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">CEDEÑO LIZARDO DE FERNÁNDEZ MARGARITA MARÍA</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Cedeño-Margarita">Ver informacion</button>
                        <div class="modal fade bd-Cedeño-Margarita" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">CEDEÑO LIZARDO DE
                                                    FERNÁNDEZ MARGARITA MARÍA</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: República dominicana</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top" src="assets/img/HD/rd/no-imagen.PNG"
                                                            alt="CEDEÑO LIZARDO DE FERNÁNDEZ, MARGARITA MARÍA">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 5 /rd Cedeño-Margarita-->

            </div>
            <div class="card-deck">

                <!--inicio 6 /rd Coronado-Luis-->
                <div class="card"> x </div>
                <!-- Fin 6 /rd Coronado-Luis-->

                <!--inicio 7 /rd De-Franklin-->
                <div class="card"> x </div>
                <!-- Fin 7 /rd De-Franklin-->

                <!--inicio 8 /rd Díaz-Élsido-->
                <div class="card"> x </div>
                <!-- Fin 8 /rd Díaz-Élsido-->

                <!--inicio 9 /rd Didiez-Namibia-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/rd/9Didiez-Namibia.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">DIDIEZ OGANDO NAMIBIA ANGOLA</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Didiez-Namibia">Ver informacion</button>
                        <div class="modal fade bd-Didiez-Namibia" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">DIDIEZ OGANDO NAMIBIA
                                                    ANGOLA</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: República dominicana</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/rd/9Didiez-Namibia.jpg"
                                                            alt="DIDIEZ OGANDO, NAMIBIA ANGOLA">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Médico ayudante del Instituto de Seguro Social. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Miembro del Partido Demócrata Popular desde el año 1995; Presidenta
                                                    del Frente de la Juventud en el periodo 1998-2002; Presidenta del
                                                    Frente Femenino en el periodo 2002-2010; Directora de Planes
                                                    Sociales del Partido en el periodo 2010-2016; Primer Vicepresidente
                                                    del partido desde 2016; Diputada al Parlamento Centroamericano para
                                                    el periodo 2020-2024. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 9 /rd Didiez-Namibia-->

                <!--inicio 10 /rd García-Silvia-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/rd/10García-Silvia.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">GARCÍA POLANCO SILVIA</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-García-Silvia">Ver informacion</button>
                        <div class="modal fade bd-García-Silvia" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">GARCÍA POLANCO SILVIA
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: República dominicana</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/rd/10García-Silvia.jpg"
                                                            alt="GARCÍA POLANCO, SILVIA">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Doctora en Derecho y Abogada de la Universidad Dominicana O &amp; M;
                                                    con una Maestría en Derecho y Relaciones Internacionales de la
                                                    Universidad Autónoma de Santo Domingo, UASD. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Presidenta del PARLACEN 2023-2024; ha desarrollado una fructífera
                                                    labor parlamentaria durante tres períodos consecutivos siendo estos:
                                                    2010-2016; 2016-2020 y 2020-2024; en los cuales ha sido electa
                                                    Vicepresidenta de Junta Directiva en los años 2012-2013 y 2018-2019;
                                                    Presidenta de la Comisión de la Mujer, Niñez, Juventud y Familia
                                                    2022-2023; cargo que también desempeñó en los años 2016-2017 y
                                                    pertenece al Grupo Parlamentario Integración Centro Democrático,
                                                    GPICD. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 10 /rd García-Silvia-->

            </div>
            <div class="card-deck">

                <!--inicio 11 /rd Gori-Ramón-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/rd/11Gori-Ramón.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">GORI TAVERAS RAMÓN EMILIO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Gori-Ramón">Ver informacion</button>
                        <div class="modal fade bd-Gori-Ramón" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">GORI TAVERAS RAMÓN EMILIO
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: República dominicana</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top"
                                                            src="assets/img/HD/rd/11Gori-Ramón.jpg"
                                                            alt="GORI TAVERAS, RAMÓN EMILIO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p>Planificación Estratégica, Universidad George Washington 2005;
                                                    Antropología Cristiana; Licenciatura en Mercadeo, Universidad
                                                    Abierta para Adultos UAPA 2005; Ingeniería Civil, Universidad
                                                    Católica Nordeestana UCNE 2003. </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p>Presidente del Ayuntamiento en San francisco de Macorís; Regidor del
                                                    Ayuntamiento San Francisco de Macorís (1998-2006); Fundador del
                                                    Partido Humanista Dominicano (PHD) en el año 1985; Actual Secretario
                                                    General del PHD; Secretario Nacional de Organización durante 10
                                                    años; Diputado al Parlamento Centroamericano períodos 2016-2020 y
                                                    2020-2024; Secretario de Junta Directiva2018-2019, Presidente del
                                                    Bloque Parlamentario Político, Vicepresidente de la Comisión
                                                    Agropecuaria, Medio Ambiente y Cambio Climático, Comisión de
                                                    credenciales (miembro), Vice-presidente del Parlamento
                                                    Centroamericano 2020-2021. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 11 /rd Gori-Ramón-->
                

                <!--inicio 12 /rd Guante-Kenia-->
                <div class="card"> x </div>
                <!-- Fin 12 /rd Guante-Kenia-->

                <!--inicio 13 /rd Guerrero-Eduardo-->
                <div class="card"> x </div>
                <!-- Fin 13 /rd Guerrero-Eduardo-->

                <!--inicio 14 /rd Liz-José-->
                <div class="card"> x </div>
                <!-- Fin 14 /rd Liz-José-->

                <!--inicio 15 /rd Medina-Danilo-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/rd/no-imagen.PNG" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">MEDINA SÁNCHEZ DANILO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Medina-Danilo">Ver informacion</button>
                        <div class="modal fade bd-Medina-Danilo" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">MEDINA SÁNCHEZ DANILO
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: República dominicana</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top" src="assets/img/HD/rd/no-imagen.PNG"
                                                            alt="MEDINA SÁNCHEZ, DANILO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 15 /rd Medina-Danilo-->

            </div>
            <div class="card-deck">

                <!--inicio 16 /rd Peña-José-->
                <div class="card">
                    <img class="card-img-top" src="assets/img/HD/rd/16Peña-José.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">PEÑA GUABA JOSÉ FRANCISCO</h5>
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target=".bd-Peña-José">Ver informacion</button>
                        <div class="modal fade bd-Peña-José" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">PEÑA GUABA JOSÉ FRANCISCO
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6 ml-auto">
                                                        <h3 class="section-title">{{ "Perfil " | uppercase }}</h3>
                                                        <ul>
                                                            <li>Pais: República dominicana</li>
                                                            <li>Ocupación o profesión: </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 ml-auto">
                                                        <img class="card-img-top" src="assets/img/HD/rd/16Peña-José.jpg"
                                                            alt="PEÑA GUABA, JOSÉ FRANCISCO">
                                                    </div>
                                                </div>

                                                <h3 class="section-title">{{ "Trayectoria Profesional" | uppercase }}
                                                </h3>
                                                <p> </p>
                                                <h3 class="section-title">{{ "Trayectoria Política" | uppercase }}</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin 16 /rd Peña-José-->

                <!--inicio 17 /rd Pérez-Altagracia-->
                <div class="card"> x </div>
                <!-- Fin 17 /rd Pérez-Altagracia-->

                <!--inicio 18 /rd Polanco-Ricardo-->
                <div class="card"> x </div>
                <!-- Fin 18 /rd Polanco-Ricardo-->

                <!--inicio 19 /rd Rodríguez-Santiago-->
                <div class="card"> x </div>
                <!-- Fin 19 /rd Rodríguez-Santiago-->

                <!--inicio 20 /rd Sánchez-Carlos-->
                <div class="card"> x </div>
                <!-- Fin 20 /rd Sánchez-Carlos-->

            </div>
            <div class="card-deck">

                <!--inicio 21 /rd Solís-Rosa-->
                <div class="card"> x </div>
                <!-- Fin 21 /rd Solís-Rosa-->

                <!--inicio 22 /rd Ventura-Juan-->
                <div class="card"> x </div>
                <!-- Fin 22 /rd Ventura-Juan-->

                <div class="card"></div>
                <div class="card"></div>
                <div class="card"></div>

            </div>